import React from 'react';
import MaterialUILayout from '../components/material-ui-layout';
import Icon from '../svg/MasumiHayashiFineArt.svg';
import '../components/logo.css';

const Logo = () => {
  return (
    <MaterialUILayout>
      <div className="logoDiv">
        <Icon className="logo" />
      </div>
    </MaterialUILayout>
  );
};

export default Logo;
